/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html, body {
    height: 100%
}

@media screen and (max-width: 900px) {
    div[role=dialog] {
        max-width: 35rem;
    }
}

.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.pin:hover {
    fill: #2277BD !important;
}

.swiper-pagination-bullet-active {
    background-color: lightgrey !important;
}